//Suggested location to add your overrides so that migration would be easy by just updating the SASS folder in the future
.p-datatable.p-datatable-gridlines .p-paginator-top {
  border-width : 1px 1px 0px 1px;
}
.product-badge {
  border-radius: 2px;
  padding: .25em .5rem;
  text-transform: uppercase;
  font-weight: 700;
  font-size: 12px;
  letter-spacing: .3px;
}

.product-badge.status-instock {
  background-color: #C8E6C9;
  color: #256029;
}

.product-badge.status-outofstock {
  background-color: #FFCDD2;
  color: #C63737;
}
.product-badge.status-normalofstock {
  background-color: #C0C0C0;
  color: #fff;
}
.product-badge.status-new {
  background-color: #b3e5fc;
  color: #23547b;
}
.bg-login {
  background-image: url('../images/background.jpg');
  background-repeat: no-repeat;
  background-size: cover;
  height: 100vh;
}
.top--5 {
  top: -10px ;
  right: -5px;
  padding: 6px;
  background-color: gray;
  border-radius: 100%;
  cursor: pointer;
  color: white;
}
.filter-content .p-inputtext {
  width: 100%;
}
.card-export .p-card .p-card-title {
  font-size: 1.25rem;
}
.col .p-card-body {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.p-toast{
  z-index: 999;
}
.p-input-icon-right>i{
  position: absolute;
  top: 50%;
  margin-top: -0.5rem;
}

@media only screen and (min-width: 1366px) and (max-width: 1439px) {
  .h-32rem {
    height: 35rem;
  }
  .width-dialog{
    width: 76% !important;
  }
  .width-edit {
    width: 26% !important;
  }
  .dialog-fix-height .p-dialog-content {
    overflow-y: initial !important;
  }
}
@media only screen and (min-width: 1440px) and (max-width: 1800px) {
  .width-dialog{
    width: 80% !important;
  }
  .width-edit {
    width: 24% !important;
  }
  .h-32rem {
    height: 35rem;
  }
  .dialog-fix-height .p-dialog-content {
    overflow-y: initial !important;
  }
}
@media only screen and (min-width: 1800px) {
  .width-dialog{
    width: 80% !important;
  }
  .width-edit {
    width: 20% !important;
  }
  .h-32rem {
    height: 36rem;
  }
  .dialog-fix-height .p-dialog-content {
    overflow-y: initial !important;
  }
}



#style-4::-webkit-scrollbar-track
{
	-webkit-box-shadow: inset 0 0 5px rgba(0,0,0,0.2);
	background-color: #F5F5F5;
}

#style-4::-webkit-scrollbar
{
	width: 6px;
	background-color: #F5F5F5;
}

#style-4::-webkit-scrollbar-thumb
{
	background-color: #615858;
	border: 1.5px solid #555555;
}
